<template>
	<div class="misc-wrapper">
		<div class="misc-inner p-3">

			<div class="w-75 text-center m-auto">
				<b-card
					text-variant="center"
					:class="`card ${nbresponse == 'Aprobado' ? 'card-congratulations' : 'card-error' } mb-0`"
					id="status-success"
					:status="nbresponse"
				>

					<b-avatar
						variant="warning"
						size="70"
						class="shadow mb-2"
					>
						<feather-icon
							:size="nbresponse == 'Aprobado' ? '28' : '50'"
							:icon="nbresponse == 'Aprobado' ? 'CheckIcon' : 'FrownIcon'"
						/>
					</b-avatar>
					<h1 class="mb-1 mt-50 text-white" v-if="nbresponse == 'Aprobado'"> Congratulations </h1>
					<h1 class="mb-1 mt-50" v-else-if="nbresponse == 'Cancelado'"> Your order has been <strong>{{status}}</strong>. </h1>

					<b-card-text class="m-auto w-75">

                        <span v-if="nbresponse == 'Aprobado'">Your payment was completed <strong>successfully</strong>.</span>

					</b-card-text>
				</b-card>

				<b-card class="text-left">

					<b-card-text>
						<p class="mb-0"><span class="font-weight-bold">Total:</span> {{formatCurrency(importe)}}</p>
						<p class="mb-0"><span class="font-weight-bold">Email:</span> {{email}}</p>
						<input type="text" id="status-input" :value="nbresponse" class="d-none">
					</b-card-text>

				</b-card>

			</div>

		</div>
	</div>
</template>

<script>
/* eslint-disable global-require */
import { toCurrency } from '@/helpers/helpers';
import { BCard,  BAvatar, BCardText, } from 'bootstrap-vue'

export default {
	components: {
		BCard,
		BAvatar,
		BCardText,
	},
    props: {
		nbresponse: {
			type: String,
			required: true
		},
		status: {
			type: String,
			required: false
		},
		importe: {
			type: Number,
			required: true
		},
		email: {
			type: String,
			required: true
		},
    },
	methods:{
		formatCurrency(amount) {
			return toCurrency(amount)
		}
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>


